
import { defineComponent } from 'vue';
import SalaryForm from '../components/SalaryForm.vue'
import SalaryOutput from '../components/SalaryOutput.vue';

import { ageData, formData }  from '../types';

export default defineComponent({
  name: 'SalaryCalView',
  components: {
    SalaryForm,
    SalaryOutput
  },
  data(){
    return{
      ageData: {} as ageData,
      formData: {} as formData,

      // For now passing age from form after feedback from Lidl
      // Consider using store if app expands, cause they want the form to be filled in from anywhere on the page
      age: 0 as number,
      hours: 0 as number,
    }
  },
  methods:{
    submitted(params: {data: ageData, age: number, hours: number}) {
      this.ageData = params.data;
      this.age = params.age;
      this.hours = params.hours;
    }
  },
});
