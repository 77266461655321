<template>
  <router-view/>
</template>

<style lang="less">
  @font-face {
    font-family: "Lidl Font Pro";
    src: url(./assets/fonts/LidlFontPro-Regular.woff2) format("woff2");
    font-style: normal;
    font-weight: 400;
  }

  @font-face {
    font-family: "Lidl Font Pro";
    src: url(./assets/fonts/LidlFontPro-Semibold.woff2) format("woff2");
    font-style: normal;
    font-weight: 600;
  }

  *{
    margin: 0;
    padding: 0;
    font-family: "Lidl Font Pro";
  }

  #app{
    background: #0050AA;
    color: #FFFFFF;
  }

  h1{
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;

    @media screen and (min-width: 980px){
      font-size: 30px;
      line-height: 36px;
    }
  }

  p{
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
  
</style>
