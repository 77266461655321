import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import SalaryCalView from '../views/SalaryCalView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    name: 'home',
    path: '/',
    component: SalaryCalView
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
