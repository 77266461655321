
  import { defineComponent } from 'vue';
  import salaryMixin from '../mixins/salaryMixin';
  import { formData }  from '../types';
  
  export default defineComponent({
    name: 'SalaryForm',
    mixins: [salaryMixin],
    props: {
        formData:{
            required: false,
            type: Object as () => formData
        }
    },
    data(){
      return{
        job: "Hulpkracht",
        age: 0 as number,
        hours: 0 as number,
      }
    },
    watch: {
        // Watches event comming from SalaryOutput.vue.
        formData(){
            if(this.$props.formData?.hours){
                this.hours = this.$props.formData?.hours;
            }
            
            if(this.$props.formData?.age){
                this.age = this.$props.formData?.age;
            }

            // Currently disabled, because you need aditional library for TS and refs
            /* eslint-disable */
            (this.$refs.submit_btn as any).click();
        }
    }
  });
