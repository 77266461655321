
import { defineComponent } from 'vue';
import { ageData, formData }  from '../types';

export default defineComponent({
    name: 'SalaryOutput',
    props:{
        data: {
            required: true,
            type: Object as () => ageData
        }
    },
    methods: {
        submitForm(age: number | null, hours: number | null){
            const data: formData = {
                age: age,
                hours: hours
            }

            // Event passed on to SalaryForm.vue
            this.$emit('submitForm', data)
        }
    },
    updated() {
        // Adding event listeners for possible functions in disclaimers (Found in salaryMixin.ts)
        this.$nextTick(() => {
            const holiday_btn = window.document.getElementById('holiday_btn');
            if(holiday_btn){
                /* eslint-disable */
                // @ts-ignore
                holiday_btn.addEventListener('click', () => {this.submitForm(null, 20)})
            }   
        })  
    }
});
